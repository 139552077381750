<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__main">
        <div class="footer__top">
          <hb-logo type="small" />
          <div class="footer__row">
            <div class="footer__link-wrapper">
              <nuxt-link :to="appUrl + '/about'" class="footer__link">
                {{ $t('footer.about') }}
              </nuxt-link>
              <!-- <nuxt-link to="/faq" class="footer__link">
                {{ $t('footer.help') }}
              </nuxt-link> -->
              <!-- <nuxt-link :to="appUrl + '/invite-friends'" class="footer__link">
                {{ $t('link_friends.title') }}
              </nuxt-link> -->
              <nuxt-link
                :to="appUrl + '/terms-conditions'"
                class="footer__link"
              >
                {{ $t('footer.terms_of_use') }}
              </nuxt-link>
              <nuxt-link
                :to="appUrl + '/privacy-cookie-policy'"
                class="footer__link"
              >
                {{ $t('footer.privacy_policy') }}
              </nuxt-link>
            </div>
            <div class="footer__socials">
              <a
                :href="socialUrls.facebook"
                target="_blank"
                aria-label="facebook"
              >
                <nuxt-icon name="facebook" filled></nuxt-icon>
              </a>
              <a
                :href="socialUrls.linkedin"
                target="_blank"
                aria-label="linkedin"
              >
                <nuxt-icon name="linkedin" filled></nuxt-icon>
              </a>
              <a
                :href="socialUrls.instagram"
                target="_blank"
                aria-label="instagram"
              >
                <nuxt-icon name="instagram" filled></nuxt-icon>
              </a>
              <!-- <a
                :href="socialUrls.twitter"
                target="_blank"
                aria-label="twitter"
              >
                <nuxt-icon name="twitter-x" filled></nuxt-icon>
              </a> -->
            </div>
          </div>
        </div>
        <div class="footer__bottom">
          <div class="footer__text">
            {{ $t('footer.copyright_text', { year }) }}
            <br />
            {{ $t('footer.copyright_address') }}
          </div>
          <div
            class="footer__text footer__bottom-right"
            v-html="$t('footer.crea_text')"
          ></div>
          <!--<div class="footer__made-by">-->
          <!--  Made by-->
          <!--  <a href="https://w5labs.com/" target="_blank">-->
          <!--    <nuxt-icon name="w5labs" filled />-->
          <!--  </a>-->
          <!--</div>-->
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { ref } from 'vue'
import HbLogo from '~/components/base/HbLogo.vue'
import { SOCIAL_PAGE_URLS } from '~/constants'
import { useRuntimeConfig } from '#app'

export default {
  name: 'MainFooter',
  components: { HbLogo },
  setup() {
    const year = ref(new Date().getFullYear())
    const socialUrls = ref(SOCIAL_PAGE_URLS)

    const config = useRuntimeConfig()
    const appUrl = config.public.baseUrl

    return {
      year,
      socialUrls,
      appUrl,
    }
  },
}
</script>

<style lang="scss">
.footer {
  position: relative;
  margin-top: 80px;
  padding: 65px 0 75px;
  background-color: var(--hb-white);

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 8px;
    background: linear-gradient(90deg, var(--hb-primary), var(--hb-secondary));
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 70px;
  }

  &__logo {
    width: 188px;
  }

  &__row {
    display: flex;
    align-items: center;
  }

  &__link {
    font-size: 14px;
    font-weight: 700;
    padding: 0 15px;
    text-decoration: none;
    color: var(--hb-blue1);
    transition: 0.3s all;

    &:hover {
      color: #98a5ab;
    }
  }

  &__socials {
    display: flex;
    align-items: center;
    gap: 0 14px;
    margin-left: 60px;

    a {
      display: block;
    }

    .nuxt-icon {
      font-size: 21px;
      transition: 0.3s all;
      color: var(--hb-blue1);

      svg {
        overflow: visible;
      }

      &:hover {
        color: var(--hb-primary);
      }
    }
  }

  &__bottom {
    display: flex;
    align-items: flex-end;
  }

  &__text {
    font-size: 10px;
    line-height: 18px;
    font-weight: 600;
  }

  &__bottom-right {
    margin-left: auto;
    text-align: right;
    max-width: 780px;
  }

  &__made-by {
    display: flex;
    align-items: center;
    align-self: center;
    font-size: 10px;
    border-left: 1px solid var(--hb-gray2);
    padding-left: 27px;
    margin-left: 30px;

    .nuxt-icon {
      font-size: 43px;
      margin-left: 9px;
      color: var(--hb-blue1);
    }

    @include desktop {
      order: -1;
      border: 0;
      padding-left: 0;
      margin-left: 0;
    }
  }

  @include desktop {
    &__top {
      flex-direction: column;
      align-items: center;
      gap: 65px;
    }

    &__row {
      flex-direction: column;
      align-items: center;
      gap: 65px;
    }

    &__bottom {
      flex-direction: column-reverse;
      align-items: center;
      gap: 45px;
    }

    &__text {
      text-align: center;
    }

    &__bottom-right {
      text-align: left;
      margin-left: 0;
      max-width: 100%;

      br {
        display: none;
      }
    }

    &__socials {
      margin-left: 0;
      gap: 25px;

      .nuxt-icon {
        font-size: 23px;
      }
    }
  }

  @include mobile {
    margin-top: 20px;

    &__link-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 25px;
    }

    &__link {
      font-size: 16px;
    }
  }
}
</style>
